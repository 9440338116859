import { format, subDays } from "date-fns";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGRNContext } from "../../context/GRNContext";
import { DataTable } from "primereact/datatable";
import * as Fa from "react-icons/fa";
import PrintGRNView from "../GRN/PrintGRN";
import Loader from "../../Components/Loader";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
function GRN() {
    const navigate = useNavigate();

    const currentDate = format(new Date(), "yyyy-MM-dd");
    const date = subDays(new Date(), 7);
    const sevenDays = format(date, "yyyy-MM-dd");
    const {
        findDateGRN,
        setGRNStatus,
        filterGRNData,
        getGRNPageLoad,
        printGRNCall,
        printPopup,
        loading,
    } = useGRNContext();
    const [fromDate, setFromDate] = useState(sevenDays);
    const [toDate, setToDate] = useState(currentDate);
    const [GRNType, setGRNType] = useState("All");
    const [selectedGRN, setSelectedGRN] = useState([]);

    const goForPrint = (headerId) => {
        printGRNCall([headerId]);
    };

    const download = () => {
        const selectedGRNCall = selectedGRN.map((item) => item.MASK_GRN_ID);
        printGRNCall(selectedGRNCall);
        setSelectedGRN([]);
    };

    useEffect(() => {
        getGRNPageLoad();
        setGRNStatus("All");
    }, [1]);

    console.log("grn000", filterGRNData);

    const renderActions = (rowData) => {
        return (
            <>
                <a
                    onClick={() => {
                        navigate(
                            `/purchasemanagement/goodsreceiptnote/details?grnId=${encodeURIComponent(
                                rowData.GRN_NO
                            )}`
                        );
                    }}
                >
                    {rowData.GRN_NO}
                </a>
            </>
        );
    };

    const renderAction = (rowData) => {
        return (
            <>
                {rowData.GRN_NO_TYPE === "GRN" && (
                    <div className="action-icon">
                        <div
                            className="d-inline"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Print"
                        >
                            <button
                                className="grid-icon"
                                onClick={() => {
                                    goForPrint(rowData.MASK_GRN_ID);
                                }}
                            >
                                <Fa.FaPrint />
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const customFilter = (value, filter) => {
        if (filter != null) {
            return value.toLowerCase().includes(filter.toLowerCase());
        } else return true;
    };

    return (
        <div className="col-12 pt-3">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="py-2">Goods Receipt Note</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12"></div>
            </div>
            <section className="sales-box">
                <form>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">From Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    defaultValue={sevenDays}
                                    max={currentDate}
                                    min="2010-01-01"
                                    name="date-field"
                                    onChange={(e) => {
                                        setFromDate(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">To Date</label>
                                <input
                                    type="date"
                                    defaultValue={currentDate}
                                    max={currentDate}
                                    min={fromDate}
                                    className="form-control"
                                    name="date-field"
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">GRN Type</label>
                                <select
                                    className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                    value={GRNType}
                                    onChange={(e) => setGRNType(e.target.value)}
                                >
                                    <option selected value="All">
                    All{" "}
                                    </option>
                                    <option value="N">Normal</option>
                                    <option value="G">Goods In Transit</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="float-end mt-4">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary me-3"
                                    onClick={(e) => {
                                        findDateGRN(e, fromDate, toDate, GRNType);
                                    }}
                                >
                  Get Data
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    disabled={selectedGRN.length == 0}
                                    onClick={download}
                                >
                  Download <Fa.FaDownload className="ms-2" />
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <div>
                {" "}
                {loading == true ? (
                    <Loader />
                ) : (
                    <section className="mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3>All Records</h3>
                            <div className="DataTable pt-1 pb-4">
                                <DataTable
                                    value={filterGRNData}
                                    // dataKey="GRN_NO"
                                    // paginator
                                    // rows={10}
                                    // filterDisplay="row"
                                    // showGridlines
                                    // stripedRows
                                    // showSelectAll={false}
                                    // selectionMode="checkbox"
                                    // selection={selectedGRN}
                                    // rowsPerPageOptions={[10, 25, 50]}
                                    // scrollHeight="80vh"
                                    // onSelectionChange={(e) => {
                                    //     setSelectedGRN(e.value);
                                    // }}
                                    // emptyMessage="No GRN found."
                                    // tableStyle={{ minWidth: "20rem" }}
                                    dataKey="GRN_NO"
                                    paginator
                                    rows={10}
                                    filterDisplay="row"
                                    showGridlines
                                    stripedRows
                                    showSelectAll={false}
                                    selection={selectedGRN}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    scrollHeight="80vh"
                                    onSelectionChange={(e) => {
                                        setSelectedGRN(e.value);
                                    }}
                                    emptyMessage="No GRN found."
                                    tableStyle={{ minWidth: "20rem" }}
                                >
                                    <Column
                                        headerStyle={{ width: "3rem" }}
                                        body={(rowData) =>
                                            rowData.GRN_NO_TYPE === "GIT" ? (
                                                <Checkbox
                                                    value={rowData}
                                                    onChange={() => {
                                                        const isSelected = selectedGRN.some(
                                                            (item) => item.GRN_NO === rowData.GRN_NO
                                                        );
                                                        let newSelection;
                                                        if (isSelected) {
                                                            newSelection = selectedGRN.filter(
                                                                (item) => item.GRN_NO !== rowData.GRN_NO
                                                            );
                                                        } else {
                                                            newSelection = [...selectedGRN, rowData];
                                                        }
                                                        setSelectedGRN(newSelection);
                                                    }}
                                                    checked={selectedGRN.some(
                                                        (item) => item.GRN_NO === rowData.GRN_NO
                                                    )}
                                                />
                                            ) : null
                                        }
                                    />

                                    <Column
                                        field="GRN_DATE"
                                        header="GRN Date"
                                        style={{ minWidth: "12rem" }}
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="GRN_NO"
                                        header="GRN Number"
                                        style={{ minWidth: "8rem" }}
                                        body={renderActions}
                                        filter
                                        filterMatchMode="custom"
                                        filterFunction={customFilter}
                                        filterPlaceholder="Search Here..."
                                    />
                                    <Column
                                        field="GRN_NO_TYPE"
                                        header="GRN Type"
                                        style={{ minWidth: "12rem" }}
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="SUPP_NAME"
                                        header="Supplier Name"
                                        style={{ minWidth: "12rem" }}
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column body={renderAction} header="Action "></Column>
                                    {/* {filterGRNData.map((rowData) => {
                                        if (rowData.GRN_NO_TYPE === "GRN") {
                                            return (
                                                <Column
                                                    key={rowData.MASK_GRN_ID}
                                                    selectionMode="multiple"
                                                    headerStyle={{ width: "3rem" }}
                                                ></Column>
                                            );
                                        }
                                        return null;
                                    })} */}
                                </DataTable>
                            </div>
                        </div>
                        {printPopup ? <PrintGRNView /> : null}
                    </section>
                )}
            </div>
        </div>
    );
}

export default GRN;
