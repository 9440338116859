import React, { useState, useEffect } from "react";
// import * as Ci from "react-icons/ci";
import { Link } from "react-router-dom";
// import { InputSwitch } from "primereact/inputswitch";
import { useSearchParams } from "react-router-dom";
// import { useGRNContext } from "../../context/GRNContext";
// import DataTable from "react-data-table-component";
import { DataTable } from "primereact/datatable";
//import * as Fa from "react-icons/fa";
import Loader from "../../Components/Loader";
import { Column } from "primereact/column";
// import { Checkbox } from "primereact/checkbox";
import * as Fa from "react-icons/fa";
// import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import {
    createAPIEndpointGRNNonINV,
    Endpoint,
} from "../../Services/GRNNonINV/GrnNonInvService";
import storage from "../../Services/Storage";

function ViewGRNnonINV() {
    // const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [grnDetailsData, setGrnDetailsData] = useState([]);
    // const [receivedQnt, setReceivedQnt] = useState();
    const auth = storage.getLocal("user");
    let grnId = searchParams.get("grnNonInvId");

    useEffect(() => {
        if (grnId) {
            grnDetailsPageLoad(grnId);
        } else {
            setGrnDetailsData([]);
        }
    }, []);


    const grnDetailsPageLoad = (grnId) => {
        setLoading(true);
        createAPIEndpointGRNNonINV()
            .get(`${Endpoint.GetGRNDataId}?CompId=${auth.CompId}&GrnGitNo=${grnId}`)
            .then((response) => {
                console.log("responseData", response.data);
                setGrnDetailsData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    };

    const saveGRNNonInv = ()=>{

    };

    let dummy = [
        {
            Prodcode: "PROD/130",
            PRODDESCRIPTION: "KEY CHAINS",
            Orderquantity: "100",
            Received: "70",
        },
    ];

    if (loading) {
        return (
            <>
                <Loader />
            </>
        );
    } else {
        return (
            <div>
                <div className="col-12 pt-3 pb-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mx-1">
                                    <li className="breadcrumb-item">
                                        <Link to="/purchasemanagement/goodsreceiptNoninventory">
                      GRN Non INV
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                    Details
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end">
                                <Link to="/purchasemanagement/goodsreceiptNoninventory">
                                    <button type="submit" className="btn btn-primary">
                                        <Fa.FaLongArrowAltLeft className="backIcon" />
                    Back to All Records
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <section className="sales-box">
                        <h4>Non Inventory GRN Details</h4>
                        <form>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">GRN Number </label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData.GRN_NO}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label"> GRN Date </label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData.GRN_DATE}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">Warehouse</label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData.MASK_WH_DESC}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">Supplier Name</label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData.SUPP_NAME}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">
                      Supplier Invoice Number
                                        </label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData.MASK_GRN_SUPP_INV_NO}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">Supplier Invoice Date</label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData.MASK_GRN_SUPP_INV_DATE}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">AWB</label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDetailsData.MASK_MOP_DESC}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="float-end">
                                    <button
                                        type="submit"
                                        className="btn btn-primary mt-2"
                                        onClick={saveGRNNonInv}
                                    >
                    Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </section>
                    <section className="mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3>All Records</h3>
                            <div className="DataTable pb-4 pt-1">
                                <DataTable
                                    value={dummy}
                                    dataKey="INVH_NO"
                                    paginator
                                    rows={10}
                                    // filterDisplay="row"
                                    showGridlines
                                    rowsPerPageOptions={[10, 25, 50]}
                                    scrollHeight="80vh"
                                    stripedRows
                                    globalFilterFields={["PROD_CODE", "DESCRIPTION"]}
                                    emptyMessage="No GRN Inv found."
                                    tableStyle={{ minWidth: "60rem" }}
                                >
                                    <Column field="Prodcode" header="Product Code" />
                                    <Column
                                        field="PRODDESCRIPTION"
                                        header="Product Description"
                                    />
                                    <Column field="Orderquantity" header="Order Quantity	" />

                                    <Column
                                        field="Received"
                                        header="Received Quantity"
                                        body={(rowData) => (
                                            <InputText
                                                value={rowData.amount}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    console.log(inputValue);
                                                    const regex = /^\d+$/;
                                                    if (regex.test(inputValue) || inputValue === "") {
                                                        // setReceivedQnt(inputValue);
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </DataTable>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default ViewGRNnonINV;
