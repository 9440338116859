import React from "react";
import * as Fa from "react-icons/fa";
import XLSX from "xlsx";
import { toast } from "react-toastify";
import { useState } from "react";
import {
    createAPIEndpoint,
    Endpoint,
} from "../../../Services/stockreport/StockReport";
import storage from "../../../Services/Storage";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Loader from "../../../Components/Loader";
const StockInHandNI = () => {
    const [productCode, setProductCode] = useState("");
    // const[showAll,setShowAll]=useState(false);
    const [errorLable, setErrorLabel] = useState(false);
    const [submitBtn, setSubmitBtn] = useState(false);
    const [allStockData, setAllStockData] = useState([]);
    const [loadingStock, setLoadingStock] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);

    const auth = storage.getLocal("user");
    const handleChange = (data) => {
        if (data == "") {
            setErrorLabel(true);
            setSubmitBtn(false);
        } else {
            setErrorLabel(false);
            setSubmitBtn(true);
        }
        setProductCode(data);
    };
    const handleGetStockData = () => {
        setAllStockData([]);
        setLoadingStock(true);
        createAPIEndpoint()
            .get(
                `${Endpoint.GetStockDataNonInv}?CICode=${auth.CICode}&Prod_Code=${productCode}&Checked=Y&strCompId=${auth.CompId}`
            )
            .then((val) => {
                setAllStockData(val.data.StockDataNI);
                setLoadingStock(false);
            })
            .catch((err) => {
                setLoadingStock(false);
                toast.error(err.response.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setAllStockData("");
            });

        setProductCode("");
        // setShowAll(false);
        setSubmitBtn(false);
    };

    const handleExportExcel = () => {
        let date = new Date();
        let day = date.getDate();
        let exactmonth = date.getMonth()+1;
        let exactDay = day.toString().padStart(2, "0");
        let year = date.getFullYear();
        setLoadingExcel(true);
        let uniqueNo = `${exactDay}${exactmonth}${year}`;
        createAPIEndpoint()
            .get(
                `${Endpoint.GetStockDataExcelNI}?CICode=${auth.CICode}&Checked=N&CompId=${auth.CompId}`
            )
            .then((val) => {
                var wb = XLSX.utils.book_new();
                var ws = XLSX.utils.json_to_sheet(val.data.StockDataNI);
                XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
                XLSX.writeFile(wb, `Stock_In_Hand_Non_INV_${uniqueNo}.xlsx`);
                setLoadingExcel(false);
            })
            .catch((error) => {
                console.error(error);
                setLoadingExcel(false);
            });
    };
    return (
        <>
            <div className="col-12 pt-3">
                <h3 className="">Stock In Hand Non Inventory</h3>
                <section className="sales-box">
                    <form>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="form-label">
                  Product Code{" "}
                                    <span className="smallLabel">
                    (**Enter Product Code to get Stock Data**)
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={productCode}
                                    onChange={(e) => handleChange(e.target.value)}
                                />
                                {errorLable && (
                                    <p style={{ color: "red" }}>Enter product code</p>
                                )}
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="float-end mt-4">
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary me-3"
                                        disabled={!submitBtn}
                                        onClick={handleGetStockData}
                                    >
                                        {loadingStock ? "Loading..." : "Get Stock Data"}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleExportExcel}
                                        disabled={loadingExcel}
                                    >
                                        {loadingExcel ? "Loading..." : "Export All"}{" "}
                                        <Fa.FaFileExport className="ms-2" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
                {loadingStock ? (
                    <Loader />
                ) : (
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h3>All Records</h3>
                        <div className="DataTable pt-1 pb-4">
                            <DataTable
                                value={allStockData}
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                scrollHeight="80vh"
                                scrollable
                                showGridlines
                                stripedRows
                                tableStyle={{ minWidth: "100rem" }}
                            >
                                <Column field="STK_PROD_ID" header="Product Code"></Column>
                                <Column
                                    field="PROD_SMALL_DESC"
                                    header="Product Description"
                                ></Column>
                                <Column field="LOCATION_DESC" header="Location"></Column>
                                <Column field="STK_TYPE_DESC" header="Stock Type"></Column>
                                <Column field="WH_DESC" header="Warehouse"></Column>
                                <Column field="PROD_BATCH_NO" header="Lot Number"></Column>
                                {/* <Column field="PRODUCT_MRP" header="MRP(₹)"></Column> */}
                                {/* <Column field="PACK_UOM_DESC" header="UOM"></Column> */}
                                <Column field="QTY_PACK" header="Quantity(Pack)"></Column>
                                {/* <Column field="QTY_LTR" header="Quantity(EA/Ltrs/Kg)"></Column> */}
                                <Column
                                    field="STK_ALLOCATED_QTY_PACK"
                                    header="Allocated Stock(Pack)"
                                ></Column>
                            </DataTable>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default StockInHandNI;
