import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";

const SegmentReactivationAllRecords = () => {
    return (
        <>
            <div className="col-12 pt-3 pb-3">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h3 className="py-2">Segment / Reactivation Change Request All Records</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="float-end mb-2">
                            <button type="button" className="btn btn-primary" >
                Back
                            </button>
                        </div>
                    </div>
                </div>

                <section className="sales-box">
                    <form>
                        <div className="row d-flex justify-content-end align-items-end">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="form-label">From Date</label>
                                <input type="date" className="form-control" />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="form-label">To Date</label>
                                <input type="date" className="form-control" />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="form-label">Select Type</label>
                                <select className="form-select form-select-lg">
                                    <option value="Cre">Segment Change</option>
                                    <option value="Deb">Re-activation</option>
                                </select>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="text-end">
                                    <button type="button" className="btn btn-primary" >
                    Get Data
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>


                {/* Segment Change */}
                <section className="mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h3 className="py-2">Records</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                            </div>
                        </div>

                        <div className="DataTable pb-4">
                            <DataTable
                                // value={filterDRCRData}
                                // dataKey="DRCR_NO"
                                paginator
                                rows={10}
                                rowsPerPageOptions={[10, 25, 50]}
                                scrollHeight="80vh"
                                filterDisplay="row"
                                showGridlines
                                scrollable
                                stripedRows
                                // selection={selectedDrCr}
                                // onSelectionChange={(e) => {
                                //     setSelectedDrCr(e.value);
                                // }}
                                emptyMessage="No Data Found."
                                tableStyle={{ minWidth: "100rem" }}
                            >
                                <Column
                                    field=""
                                    header="Customer Code"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Customer Name"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Unique Code"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Segment Code"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Proposed Segment Code"
                                ></Column>
                                <Column
                                    field=""
                                    header="Proposed Segment Name"
                                ></Column>
                                <Column
                                    field=""
                                    header="Comments"
                                ></Column>
                                <Column
                                    field=""
                                    header="Status"
                                ></Column>
                            </DataTable>
                        </div>
                    </div>
                </section>

                {/* Re-activation Change */}
                <section className="mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h3 className="py-2">Records</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                            </div>
                        </div>

                        <div className="DataTable pb-4">
                            <DataTable
                                // value={filterDRCRData}
                                // dataKey="DRCR_NO"
                                paginator
                                rows={10}
                                rowsPerPageOptions={[10, 25, 50]}
                                scrollHeight="80vh"
                                filterDisplay="row"
                                showGridlines
                                scrollable
                                stripedRows
                                // selection={selectedDrCr}
                                // onSelectionChange={(e) => {
                                //     setSelectedDrCr(e.value);
                                // }}
                                emptyMessage="No Data Found."
                                tableStyle={{ minWidth: "100rem" }}
                            >
                                <Column
                                    selectionMode="multiple"
                                    header="Select All"
                                    style={{ minwidth: "12rem" }}
                                ></Column>
                                <Column
                                    field=""
                                    header="Customer Code"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Customer Name"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Unique Code"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Segment Code"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Expiry Date"
                                ></Column>
                                <Column
                                    field=""
                                    header="Revised Expiry Date"
                                ></Column>
                                <Column
                                    field=""
                                    header="Comments"
                                ></Column>
                                <Column
                                    field=""
                                    header="Status"
                                ></Column>
                            </DataTable>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default SegmentReactivationAllRecords;