import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import * as Fa from "react-icons/fa";
import { RadioButton } from "primereact/radiobutton";
import Log_In_Process from "../../Assets/Images/manuals/Log_in_Process_User_Manual.pdf";
import Sales_Order from "../../Assets/Images/manuals/Sales_Order_User_Manual.pdf";
import Customer_Master from "../../Assets/Images/manuals/Customer_Master_User_Manual.pdf";
import Stock_Adjustment from "../../Assets/Images/manuals/Stock_Adjustment_User_Manual.pdf";
import GRN_Creation from "../../Assets/Images/manuals/GRN_Creation_User_Manual.pdf";
import Purchase_Return from "../../Assets/Images/manuals/Purchase_Return_User_Manual.pdf";
import Parameter_Settings from "../../Assets/Images/manuals/Parameter_Settings_User_Manual.pdf";
import Write_Off from "../../Assets/Images/manuals/Write_Off_User_Manual.pdf";
import Receipt from "../../Assets/Images/manuals/Receipt_User_Manual.pdf";
import Document_Adjustment from "../../Assets/Images/manuals/Document_Adjustment_User_Manual.pdf";
import Customer_Advance_Adjustment from "../../Assets/Images/manuals/Customer_Advance_Adjustment_User_Manual.pdf";
import Customer_Creation_With_Existing_Mobile_Number from "../../Assets/Images/manuals/Customer_Creation_With_Existing_Mobile_Number_User_Manual.pdf";
import InnoRise_Lite_User_Manual from "../../Assets/Images/manuals/InnoRise_Lite_User_Manual.pdf";

const Help = () => {
    const [msgPopUp, setMsgPopUp] = useState(false);
    const [videosrc, setVideosrc] = useState(false);
    const [changeSectionVT, setChangeSectionVT] = useState(false);
    const [changeSectionUM, setChangeSectionUM] = useState(false);

    const categories = [
        { name: "Video Tutorials", key: "VT" },
        { name: "User Manuals", key: "UM" },
    ];

    let videoCategoryObj = [
        {
            id: 1,
            thumbnail: require("../../Assets/Images/manuals/login.jpg"),
            url: "https://www.youtube.com/embed/EqsgGE3mHoM",
            title: "Login",
        },
        {
            id: 2,
            thumbnail: require("../../Assets/Images/manuals/forgot_password.jpg"),
            url: "https://www.youtube.com/embed/jkCnUWPLAV4",
            title: "Forgot Password",
        },
        {
            id: 3,
            thumbnail: require("../../Assets/Images/manuals/customer_creation.jpg"),
            url: "https://www.youtube.com/embed/LemQ2u0br-M",
            title: "Customer Creation",
        },
        {
            id: 4,
            thumbnail: require("../../Assets/Images/manuals/stock_adjustment.jpg"),
            url: "https://www.youtube.com/embed/SXVTrLrKbvQ",
            title: "Stock Adjustment",
        },
        {
            id: 5,
            thumbnail: require("../../Assets/Images/manuals/grn.jpg"),
            url: "https://www.youtube.com/embed/VasYRifZAws",
            title: "Goods Receipt Note(GRN)",
        },
        {
            id: 6,
            thumbnail: require("../../Assets/Images/manuals/so_auto_allocation.jpg"),
            url: "https://www.youtube.com/embed/_LNLLiijZvY",
            title: "Sales Order Auto Allocation and Invoicing",
        },
        {
            id: 7,
            thumbnail: require("../../Assets/Images/manuals/so_reallocation.jpg"),
            url: "https://www.youtube.com/embed/hn-ltorqn1o",
            title: "Sales Order Reallocation",
        },
        {
            id: 8,
            thumbnail: require("../../Assets/Images/manuals/purchase_return_partial_process.jpg"),
            url: "https://www.youtube.com/embed/ZRt47T9GX-0",
            title: "Purchase Return Partial Process",
        },
        {
            id: 9,
            thumbnail: require("../../Assets/Images/manuals/purchase_return_full_process.jpg"),
            url: "https://www.youtube.com/embed/OBZHAgx7UtI",
            title: "Purchase Return Full Process",
        },
        {
            id: 10,
            thumbnail: require("../../Assets/Images/manuals/negative_stock_adjustment.jpg"),
            url: "https://www.youtube.com/embed/ghEak9s_-80",
            title: "Negative Stock Adjustment Process",
        },
        {
            id: 11,
            thumbnail: require("../../Assets/Images/manuals/positive_stock_adjustment.jpg"),
            url: "https://www.youtube.com/embed/X_M3shtcgG8",
            title: "Positive Stock Adjustment Process",
        },
        {
            id: 12,
            thumbnail: require("../../Assets/Images/manuals/parameter_settings.jpg"),
            url: "https://www.youtube.com/embed/0WocKxbmU_c",
            title: "Parameter Settings",
        },
        {
            id: 13,
            thumbnail: require("../../Assets/Images/manuals/customer_advance_adjustment.jpg"),
            url: "https://www.youtube.com/embed/MHSE5Wxhdug",
            title: "Customer Advance Adjustment",
        },
        {
            id: 14,
            thumbnail: require("../../Assets/Images/manuals/customer_advance_adjustment_cancellation.jpg"),
            url: "https://www.youtube.com/embed/Q93A4P6G5pE",
            title: "Customer Advance Adjustment Cancellation",
        },
        {
            id: 15,
            thumbnail: require("../../Assets/Images/manuals/customer_outstanding_write_off.jpg"),
            url: "https://www.youtube.com/embed/WaB0szEXzEk",
            title: "Customer Outstanding Write Off",
        },
        {
            id: 16,
            thumbnail: require("../../Assets/Images/manuals/write_off.jpg"),
            url: "https://www.youtube.com/embed/u8VWc-xKQ9A",
            title: "Write Off",
        },
        {
            id: 17,
            thumbnail: require("../../Assets/Images/manuals/document_adjustment.jpg"),
            url: "https://www.youtube.com/embed/mE9n4ZXHTg4",
            title: "Document Adjustment",
        },
        {
            id: 18,
            thumbnail: require("../../Assets/Images/manuals/receipt.jpg"),
            url: "https://www.youtube.com/embed/YDZiz9SC4gA",
            title: "Receipt",
        },
        {
            id: 19,
            thumbnail: require("../../Assets/Images/manuals/pwa.jpg"),
            url: "https://www.youtube.com/embed/LVlaOMtTYvY",
            title: "Innorise DMS Lite Mobile Version",
        },
    ];

    let exportPdf = [
        { link: Log_In_Process, title: "Log In Process" },
        { link: Sales_Order, title: "Sales Order" },
        { link: Customer_Master, title: "Customer Master" },
        { link: Stock_Adjustment, title: "Stock Adjustment " },
        { link: GRN_Creation, title: "GRN Creation" },
        { link: Purchase_Return, title: "Purchase Return" },
        { link: Parameter_Settings, title: "Parameter Settings" },
        { link: Write_Off, title: "Write Off" },
        { link: Document_Adjustment, title: "Document Adjustment" },
        { link: Receipt, title: "Receipt" },
        { link: Customer_Advance_Adjustment, title: "Customer Advance Adjustment" },
        { link: Customer_Creation_With_Existing_Mobile_Number, title: "Customer Creation With Existing Mobile Number" },
        { link: InnoRise_Lite_User_Manual, title: "InnoRise Lite" },
    ];
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);

    const handlechangeCategory = (e) => {
        setSelectedCategory(e.value);
        if (e.value.key == "VT") {
            setChangeSectionVT(true);
            setChangeSectionUM(false);
        } else {
            setChangeSectionVT(false);
            setChangeSectionUM(true);
        }
    };

    useEffect(() => {
        setChangeSectionVT(true);
    }, []);

    const showPopUpMsg = (url) => {
        console.log(url);
        setMsgPopUp(true);
        setVideosrc(url);
    };

    console.log("videoCategoryObj", videoCategoryObj);

    return (
        <main>
            <div className="col-12 pt-3 pb-4">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h3 className="py-2">Help</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                            
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 mb-3 d-flex justify-content-center">
                    {categories.map((category) => {
                        return (
                            <div
                                key={category.key}
                                className="flex align-items-center mx-2 mb-2"
                            >
                                <RadioButton
                                    inputId={category.key}
                                    name="category"
                                    value={category}
                                    onChange={(e) => handlechangeCategory(e)}
                                    checked={selectedCategory.key === category.key}
                                />
                                <label htmlFor={category.key} className="mx-2">
                                    {category.name}
                                </label>
                            </div>
                        );
                    })}
                </div>

                {changeSectionVT && (
                    <section className="Videos">
                        <div className="row">
                            {videoCategoryObj.map((item) => (
                                <>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <a
                                            onClick={() => showPopUpMsg(item.url)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="VideoBox">
                                                <img src={item.thumbnail} style={{ width: "100%" }} />
                                                <p className="mt-2 mb-1 text-center">{item.title}</p>
                                            </div>
                                        </a>
                                    </div>
                                </>
                            ))}
                        </div>
                    </section>
                )}

                {changeSectionUM && (
                    <section className="Files">
                        <div className="sales-box">
                            <div className="row">
                                {exportPdf.map((item) => (
                                    <>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <a href={item.link} target="_blank" rel="noreferrer">
                                                <button
                                                    type="button"
                                                    title="Download"
                                                    className="PDF_button"
                                                >
                                                    {item.title} <Fa.FaDownload className="Ico" />
                                                </button>
                                            </a>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </section>
                )}
            </div>

            {
                <Dialog
                    header="Tutorial Video"
                    visible={msgPopUp}
                    style={{ width: "90vw" }}
                    onHide={() => setMsgPopUp(false)}
                    draggable={false}
                    resizable={false}
                >
                    <div>
                        <iframe
                            width="100%"
                            height="400px"
                            src={videosrc}
                            title="Login Process for Innorise DMS Application"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                        ></iframe>
                    </div>
                </Dialog>
            }
        </main>
    );
};

export default Help;
