import Axios from "../../api/Axios";
const BASE_URL = "Reports/";


export const EndpointReports = {
    GetGRNExcelData : "GetGRNExcelData",
    GetGRNExcelNonInv : "GetGRNExcelNonInv"
};

export const createAPIEndpointReports= () => {
    return {get:(Endpoint)=>Axios.get(BASE_URL+Endpoint)};
};