import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { RadioButton } from "primereact/radiobutton";
import React from "react";
import * as Fa from "react-icons/fa";
import { Link } from "react-router-dom";

const CreateChangeRequest = () => {
    return (
        <>
            <div className="col-12 pt-3 pb-3">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h3 className="py-2">Segment / Reactivation Change Request</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="float-end mb-2">
                            <Link to="/masters/segmentreactivationchangerequestallrecords">
                                <button type="button" className="btn btn-outline-primary me-3">
                                All Records
                                </button>
                            </Link>
                            <button type="button" className="btn btn-primary" >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <form>
                        <div className="my-3 d-flex justify-content-center">
                            <span className='me-3'>Select Type :</span>
                            <div className="d-inline-flex">
                                <RadioButton />
                                <label className="mx-2">Segment Change</label>
                                <RadioButton />
                                <label className="mx-2">Re-activation</label>
                            </div>
                        </div>
                    </form>
                </div>

                {/* Segment Change */}
                <section className="mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h3 className="py-2">Records</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="float-end">
                                    <button type="button" className="btn btn-light" >
                                        Select Proposed Segment <Fa.FaSearch className="ms-2" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="DataTable pb-4">
                            <DataTable
                                // value={filterDRCRData}
                                // dataKey="DRCR_NO"
                                paginator
                                rows={10}
                                showSelectAll={false}
                                rowsPerPageOptions={[10, 25, 50]}
                                scrollHeight="80vh"
                                filterDisplay="row"
                                showGridlines
                                scrollable
                                stripedRows
                                // selection={selectedDrCr}
                                // onSelectionChange={(e) => {
                                //     setSelectedDrCr(e.value);
                                // }}
                                emptyMessage="No Data Found."
                                tableStyle={{ minWidth: "100rem" }}
                            >
                                <Column
                                    selectionMode="multiple"
                                    header="Select All"
                                    style={{ minwidth: "12rem" }}
                                ></Column>
                                <Column
                                    field=""
                                    header="Customer Code"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Customer Name"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Unique Code"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Segment Code"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Proposed Segment"
                                ></Column>
                                {/* Input Text Here */}
                                <Column
                                    field=""
                                    header="Comments"
                                ></Column>
                                {/* Input Text Here */}
                                <Column header="Action "></Column>
                                {/* There should be a View/Edit Icon, which redirect Customer Master Page */}
                            </DataTable>
                        </div>
                    </div>
                </section>

                {/* Re-activation Change */}
                <section className="mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h3 className="py-2">Records</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">

                            </div>
                        </div>

                        <div className="DataTable pb-4">
                            <DataTable
                                // value={filterDRCRData}
                                // dataKey="DRCR_NO"
                                paginator
                                rows={10}
                                showSelectAll={false}
                                rowsPerPageOptions={[10, 25, 50]}
                                scrollHeight="80vh"
                                filterDisplay="row"
                                showGridlines
                                scrollable
                                stripedRows
                                // selection={selectedDrCr}
                                // onSelectionChange={(e) => {
                                //     setSelectedDrCr(e.value);
                                // }}
                                emptyMessage="No Data Found."
                                tableStyle={{ minWidth: "100rem" }}
                            >
                                <Column
                                    selectionMode="multiple"
                                    header="Select All"
                                    style={{ minwidth: "12rem" }}
                                ></Column>
                                <Column
                                    field=""
                                    header="Customer Code"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Customer Name"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Unique Code"
                                    filterMatchMode="contains"
                                    filter
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field=""
                                    header="Expiry Date"
                                ></Column>
                                <Column
                                    field=""
                                    header="Revised Expiry Date"
                                ></Column>
                                {/* Input Date Here */}
                                <Column
                                    field=""
                                    header="Comments"
                                ></Column>
                                {/* Input Text Here */}
                                <Column header="Action "></Column>
                                {/* There should be a View/Edit Icon, which redirect Customer Master Page */}
                            </DataTable>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default CreateChangeRequest;