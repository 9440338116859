import { format, subDays } from "date-fns";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import * as Fa from "react-icons/fa";
import Loader from "../../Components/Loader";
import { Column } from "primereact/column";
import { toast } from "react-toastify";
import {
    createAPIEndpointGRNNonINV,
    Endpoint,
} from "../../Services/GRNNonINV/GrnNonInvService";
import storage from "../../Services/Storage";
// import { Checkbox } from "primereact/checkbox";


function GRNnonInv() {
    const nav = useNavigate();
    const currentDate = format(new Date(), "yyyy-MM-dd");
    const date = subDays(new Date(), 365);
    const sevenDays = format(date, "yyyy-MM-dd");
    const [fromDate, setFromDate] = useState(sevenDays);
    const [toDate, setToDate] = useState(currentDate);
    const [GRNType, setGRNType] = useState("All");
    const [selectedGRN, setSelectedGRN] = useState([]);
    const [allGRNData, setAllGRNData] = useState([]);
    const [loading, setLoading] = useState(false);
    const auth = storage.getLocal("user");
    useEffect(() => {
        handleGetGRNData();
    }, []);

    const renderActions = (rowData) => {
        return (
            <>
                <div
                    className="d-inline"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="View GRN/GIT Details"
                >
                    <button
                        className="grid-icon"
                        onClick={() => {
                            nav(
                                `/purchasemanagement/goodsreceiptNoninventoryView?grnNonInvId=${encodeURIComponent(
                                    rowData.GRN_NO
                                )}`
                            );
                        }}
                    >
                        <Fa.FaEye />
                    </button>
                </div>
            </>
        );
    };

    // const customFilter = (value, filter) => {
    //   if (filter != null) {
    //     return value.toLowerCase().includes(filter.toLowerCase());
    //   } else return true;
    // };

    const handleGetGRNData = async () => {
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");

        let from_date = format(fDate, "yyyy/MM/dd");
        let to_date = format(tDate, "yyyy/MM/dd");
        if (from_date > to_date) {
            toast.warn("From Date should be equal to or less than To Date", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
            return;
        } else {
            setLoading(true);
            createAPIEndpointGRNNonINV()
                .get(
                    `${Endpoint.GetGRN}?CompId=${auth.CompId}&fromDate=${fromDateRange}&toDate=${toDateRange}&userId=${auth.UserId}&GRNType=${GRNType}`
                )
                .then((response) => {
                    console.log("responseData", response.data.Data);
                    setAllGRNData(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                });
        }
    };


    return (
        <div className="col-12 pt-3">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="py-2">Non Inventory Goods Receipt Note</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12"></div>
            </div>
            <section className="sales-box">
                <form>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">From Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    defaultValue={sevenDays}
                                    min="2010-01-01"
                                    name="date-field"
                                    onChange={(e) => {
                                        setFromDate(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">To Date</label>
                                <input
                                    type="date"
                                    defaultValue={currentDate}
                                    max={currentDate}
                                    min="2010-01-01"
                                    className="form-control"
                                    name="date-field"
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">GRN Type</label>
                                <select
                                    className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                    value={GRNType}
                                    onChange={(e) => setGRNType(e.target.value)}
                                >
                                    <option selected value="All">
                    All{" "}
                                    </option>
                                    <option value="N">GRN</option>
                                    <option value="G">GIT</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="float-end mt-4">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary me-3"
                                    onClick={() => {
                                        handleGetGRNData();
                                    }}
                                >
                  Get GRN
                                </button>
                            </div>
                            {/* {selectedGRN.length > 0 && (
                <div className="float-end">
                  <button
                    type="button"
                    className="btn btn-outline-primary me-3"
                    onClick={(e) => {
                      handleGetGRNData();
                    }}
                  >
                    Save Multiple GRN
                  </button>
                </div>
              )} */}
                        </div>
                    </div>
                </form>
            </section>
            <div>
                {" "}
                {loading == true ? (
                    <Loader />
                ) : (
                    <section className="mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3>All Records</h3>
                            <div className="DataTable pt-1 pb-4">
                                <DataTable
                                    value={allGRNData}
                                    dataKey="GRN_NO"
                                    paginator
                                    rows={10}
                                    filterDisplay="row"
                                    showGridlines
                                    stripedRows
                                    showSelectAll={false}
                                    selection={selectedGRN}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    scrollHeight="80vh"
                                    onSelectionChange={(e) => {
                                        setSelectedGRN(e.value);
                                    }}
                                    emptyMessage="No GRN found."
                                    tableStyle={{ minWidth: "20rem" }}
                                >
                                    {/* <Column
                    headerStyle={{ width: "3rem" }}
                    body={(rowData) =>
                      rowData.GRN_NO_TYPE === "GIT" ? (
                        <Checkbox
                          value={rowData}
                          onChange={(e) => {
                            const isSelected = selectedGRN.some(
                              (item) => item.MASK_GRN_ID === rowData.MASK_GRN_ID
                            );
                            let newSelection;
                            if (isSelected) {
                              newSelection = selectedGRN.filter(
                                (item) => item.MASK_GRN_ID !== rowData.MASK_GRN_ID
                              );
                            } else {
                              newSelection = [...selectedGRN, rowData];
                            }
                            setSelectedGRN(newSelection);
                          }}
                          checked={selectedGRN.some(
                            (item) => item.MASK_GRN_ID === rowData.MASK_GRN_ID
                          )}
                        />
                      ) : null
                    }
                  /> */}
                                    <Column
                                        field="GRN_NO"
                                        header="GRN Number"
                                        style={{ minWidth: "8rem" }}
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    />
                                    <Column
                                        field="GRN_DATE"
                                        header="GRN Date"
                                        style={{ minWidth: "12rem" }}
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="SUPP_NAME"
                                        header="Supplier Name"
                                        style={{ minWidth: "12rem" }}
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="GRN_NO_TYPE"
                                        header="Status"
                                        style={{ minWidth: "12rem" }}
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="GRN_NO"
                                        header="GRN Number"
                                        style={{ minWidth: "8rem" }}
                                        body={renderActions}
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    />
                                </DataTable>
                            </div>
                        </div>
                        {/* {printPopup ? <PrintGRNView /> : null} */}
                    </section>
                )}
            </div>
        </div>
    );
}

export default GRNnonInv;
