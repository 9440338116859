import Axios from "../../api/Axios";
const BASE_URL = "GRN/";
export const Endpoint = {
    GetGRN: "GetGRN",
};

export const createAPIEndpointGRNNonINV = () => {
    return { get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint,data)=> Axios.post(BASE_URL+Endpoint,data)
    };
};
