import { useFormik } from "formik";
import { AutoComplete } from "primereact/autocomplete";
import { InputText } from "primereact/inputtext";
import React from "react";
import { initialValues } from "../../Validations/DeliveryChallanValidation";
import { classNames } from "primereact/utils";
import {
    Endpoint,
    createAPIEndpoint,
} from "../../Services/DeliveryChallan/DeliveryChlnService";
import storage from "../../Services/Storage";
import { useState } from "react";
import { useEffect } from "react";
import { format } from "date-fns";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as Fa from "react-icons/fa";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader";
import { useDeliveryChallanContext } from "../../context/DeliveryChallanContext";
import PrintView from "./PrintView";
import { MdRefresh } from "react-icons/md";
import {
    EndpointSalesReturn,
    createAPIEndpointSalesReturn,
} from "../../Services/SalesReturnAdd/SalesReturnServices";

let prdDtl;
const DeliveryChallan = () => {
    const auth = storage.getLocal("user");
    const currentDate = format(new Date(), "dd/MM/yyyy");
    const [wareHouseSuggestion, setWareHouseSuggestion] = useState("");
    const [wareHouse, setWareHouse] = useState("");
    const [customerSuggestion, setCustomerSuggestion] = useState("");
    const [customer, setCustomer] = useState("");
    const [payTermSuggestion, setPayTermSuggestion] = useState("");
    const [payTerm, setPayTerm] = useState("");
    const [salesExecSuggestion, setSalesExecSuggestion] = useState("");
    const [salesExec, setSalesExec] = useState("");
    const [mopSuggestion, setMOPSuggestion] = useState("");
    const [MOP, setMOP] = useState("");
    const [DivnID, setDivnID] = useState("");
    // const [TaxFormId, setTaxFormId] = useState("");
    const [productCodeSuggestion, setProductCodeSuggestion] = useState("");
    const [productCode, setProductCode] = useState("");
    const [populateDataTable, setPopulateDataTable] = useState([]);
    const [onChangePaytermFlag, setOnchangePaytermFlag] = useState(false);
    const [onChangeMOPFlag, setOnchangeMOPFlag] = useState(false);
    // const [onChangeSEFlag, setOnchangeSEFlag] = useState(false);
    const [disableFlag, setDisableFlag] = useState(false);
    const [customerData, setCustomerData] = useState("");
    const [customerData2, setCustomerData2] = useState("");
    const [branchId, setBranchId] = useState("");
    const [cartDisable, setCartDisable] = useState(false);
    // const [pageLoading, setPageLoading] = useState(false);
    // const [custid, setCustid] = useState("");
    const [exchRate, setExchRate] = useState("");
    const [billingInst, setBillingInst] = useState("");
    const [registCode, setRegistCode] = useState("");
    const [chkInHnd, setChkInHnd] = useState(null);
    const [roundOff, setRoundOff] = useState("");
    const [salActId, setSalActId] = useState("");
    const [salDiscId, setSalDiscId] = useState("");
    const [MopId, setMopId] = useState("");
    const [salesDiscPerc, setSalesDiscPerc] = useState("");
    const [delivInstr, setDelivInstr] = useState("");
    const [ESPECIE, setESPECIE] = useState("");
    const [serie, setSerie] = useState("");
    const [ORDM, setORDM] = useState("");
    const [customerLoading, setCustomerLoading] = useState(false);
    const [deliveryChallanNo, setDeliveryChallanNo] = useState("");

    const { setPrintData, setDCPopup, pageLoading, setPageLoading } =
        useDeliveryChallanContext();

    //formik

    function handleSubmit(data) {
        prdDtl = {
            stock_type: "Non Inventory",
            pvcINVD_ID: "",
            prdtDIVN_ID: DivnID,
            pvcPROD_ID: data.product_code.CODE,
            pvcPROD_LONG_DESC: data.product_description,
            pvcINVD_PROD_STK_TYPE_ID: "2",
            pvcSCHM_ID: "",
            pnmINVD_TOT_INV_QTY: data.order_quantity,
            pvcINVD_PROD_BATCH_NO: data.product_code.PROD_BATCH_NO,
            pnmINVD_PROD_PRICE: "0",
            pvcPROD_BUY_PRICE: "0",
            pvcSCHM_TAG: "N",
            pvcEVAL_TAG: data.product_code.MASK_LOT_TAG,
            pnmDBQTY: "0",
            pvcTAG: "",
            pvcSODT_ID: "",
            pvcCFOP_ID: "",
            pvcSTK_REF_DOC_NO: "",
            pdtSTK_REF_DOC_DATE: currentDate,
            pnmSTK_TRANS_PRICE: "0",
            pnmSTK_TRANS_FREIGHT: "0",
            pnmSTK_TRANS_TAX: "0",
            pnmSTK_TRANS_OTHER: "0",
            pnmSTK_TRANS_VAT: "0",
            pnmSTK_RELEASE_QTY: "0",
            remarks: data.remarks,
            payTermId: onChangePaytermFlag
                ? data.payment_term.MASK_PAY_TERM_ID
                : customerData.MASK_PAY_TERM_ID,
            cust_name: data.cust_name.NAME,
            payment_mode: onChangeMOPFlag
                ? data.payment_mode.MASK_MOP_ID
                : data.payment_mode,
            available_quantity: data.available_quantity,
        };
        const maskProdId = prdDtl.pvcPROD_ID;
        let isDuplicateID = populateDataTable.some((item) => {
            if (item.pvcPROD_ID === maskProdId) {
                if (item.pvcINVD_PROD_BATCH_NO === prdDtl.pvcINVD_PROD_BATCH_NO)
                    return true;
                else
                    return false;
            }
            else
                return false;
        });
        console.log("isDuplicateId", isDuplicateID);

        if (isDuplicateID) {
            toast.error("Product already added to the cart.", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
            isDuplicateID = false;
        } else {
            toast.success(
                "Product added to the cart.",
                {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                }

            );
            setPopulateDataTable((prevState) => [...prevState, prdDtl]);
        }
        setDisableFlag(true);
        formik.values.lot_no = "";
        formik.values.available_quantity = "";
        formik.values.product_code = "";
        formik.values.product_description = "";
        formik.values.order_quantity = "";
    }

    // after page load

    const formik = useFormik({
        initialValues: initialValues,
        validate: (data) => {
            let errors = {};

            if (!data.product_code.toString().trim()) {
                errors.product_code = "Product code is required.";
            }
            if (!data.delivery_Challan_date.trim()) {
                errors.delivery_Challan_date = "Delivery Challan date is required.";
            }
            if (!data.warehouse.toString().trim()) {
                errors.warehouse = "Warehouse is required.";
            }
            if (!data.cust_name.toString().trim()) {
                errors.cust_name = "Customer Name is required.";
            }

            if (!data.payment_term.toString().trim()) {
                errors.payment_term = "Payment Term is required.";
            }

            if (!data.sales_executive.toString().trim()) {
                errors.sales_executive = "Sales Executive is required.";
            }
            if (!data.payment_mode.toString().trim()) {
                errors.payment_mode = "Mode of Payment is required.";
            }
            if (!data.order_quantity.trim()) {
                errors.order_quantity = "Order quantity is required.";
            } else if (!/^[0-9]+$/.test(data.order_quantity)) {
                errors.order_quantity = "Please enter only numbers.";
            } else if (parseInt(data.order_quantity) < 1) {
                errors.order_quantity = "Please enter values more than 0.";
            } else if (
                parseInt(data.available_quantity) < parseInt(data.order_quantity)
            ) {
                errors.order_quantity = "Order quantity unavailable.";
            } else if (data.order_quantity > 9999) {
                errors.order_quantity = "Order quantity limit exceeds.";
            }
            if (data.remarks.trim().length > 100) {
                errors.remarks = "Limit reached.";
            }

            return errors;
        },

        onSubmit: (data) => {
            handleSubmit(data);
        },
    });
    const isFormFieldInvalid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? (
            <small className="p-error">{formik.errors[name]}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };

    // //api calling

    const WareHouseTemplate = (stateFilter) => {
        return <>{stateFilter.DESCRIPTION}</>;
    };
    const CustomerTemplate = (stateFilter) => {
        return <>{stateFilter.NAME}</>;
    };
    const SalesExecTemplate = (stateFilter) => {
        return <>{stateFilter.NAME}</>;
    };
    const PayTermTemplate = (stateFilter) => {
        return <>{stateFilter.DESCRIPTION}</>;
    };

    const MOPTemplate = (stateFilter) => {
        return <>{stateFilter.DESCRIPTION}</>;
    };
    const ProductCodeTemplate = (stateFilter) => {
        return (
            <>
                {stateFilter.CODE}-{stateFilter.DESCRIPTION}-{stateFilter.PROD_BATCH_NO}
                -{stateFilter.STOCK_AVAILABLE}
            </>
        );
    };

    const PopulateWareHouse = () => {
        createAPIEndpointSalesReturn()
            .getDefaultValue(
                `${EndpointSalesReturn.WhDefaultVal}?CompId=${auth.CompId}&UserId=${auth.UserId}`
            )
            .then((ress) => {
                createAPIEndpoint()
                    .get(
                        `${Endpoint.GetWarehouse}?UserId=${auth.UserId}&CompId=${auth.CompId}`
                    )
                    .then((res) => {
                        initialValues.warehouse = res.data.Data.find((val) => {
                            return val.MASK_WH_ID === ress.data.Id;
                        });
                        let values = res.data.Data.map((val) => {
                            return val;
                        });
                        setWareHouseSuggestion(values);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const populateCustomer = () => {
        setCustomerLoading(true);
        let response = [];
        createAPIEndpoint()
            .get(`${Endpoint.GetDeliveryChallanCustomer}?CompId=${auth.CompId}`)
            .then((res) => {
                response = res.data.Data;
                let values = response.map((val) => {
                    return val;
                });
                setCustomerSuggestion(values);
                setCustomerLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const whid = formik.values.warehouse?.MASK_WH_ID;
    const CustId = formik.values.cust_name?.MASK_CUST_ID;

    const PopulatePayTerm = () => {
        let response = [];
        createAPIEndpoint()
            .get(`${Endpoint.GetPayTerm}`)
            .then((res) => {
                response = res.data.Data;
                let values = response.map((val) => {
                    return val;
                });
                setPayTermSuggestion(values);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const PopulateMOP = () => {
        let response = [];
        createAPIEndpoint()
            .get(`${Endpoint.GetMOP}`)
            .then((res) => {
                response = res.data.Data;
                const modeOfPayment = response.filter((val) => {
                    return val.CODE == "CASH" || val.CODE == "CHQ";
                });
                setMOPSuggestion(modeOfPayment);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const PopulateSalesExec = () => {
        let response = [];
        createAPIEndpoint()
            .get(`${Endpoint.GetSalesExecutive}?CompId=${auth.CompId}`)
            .then((res) => {
                response = res.data.Data;
                let values = response.map((val) => {
                    return val;
                });
                setSalesExecSuggestion(values);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const divnID = () => {
        createAPIEndpoint()
            .getDefaultValue(
                `${Endpoint.GetDefaultValue}?CompId=${auth.CompId}&defId=20`
            )
            .then((res) => {
                setBillingInst(res.data.Id);
            });
        createAPIEndpoint()
            .getDefaultValue(
                `${Endpoint.GetDefaultValue}?CompId=${auth.CompId}&defId=7`
            )
            .then((res) => {
                if (res.data.Id == "TM") {
                    setORDM("");
                } else {
                    setORDM(res.data.Id);
                }
            });
        createAPIEndpoint()
            .getDefaultValue(
                `${Endpoint.GetDefaultValue}?CompId=${auth.CompId}&defId=SALES`
            )
            .then((res) => {
                setSalActId(res.data.Id);
            });
        createAPIEndpoint()
            .getDefaultValue(
                `${Endpoint.GetDefaultValue}?CompId=${auth.CompId}&defId=SALDISC`
            )
            .then((res) => {
                setSalDiscId(res.data.Id);
            });
        createAPIEndpoint()
            .getDefaultValue(
                `${Endpoint.GetDefaultValue}?CompId=${auth.CompId}&defId=ROUNDOFF`
            )
            .then((res) => {
                setRoundOff(res.data.Id);
            });
        createAPIEndpoint()
            .getDefaultValue(
                `${Endpoint.GetDefaultValue}?CompId=${auth.CompId}&defId=21`
            )
            .then((res) => {
                setDelivInstr(res.data.Id);
            });
        createAPIEndpoint()
            .getDefaultValue(
                `${Endpoint.GetDefaultValue}?CompId=${auth.CompId}&defId=22`
            )
            .then((res) => {
                setESPECIE(res.data.Id);
            });
        createAPIEndpoint()
            .getDefaultValue(
                `${Endpoint.GetDefaultValue}?CompId=${auth.CompId}&defId=1`
            )
            .then((res) => {
                setDivnID(res.data.Id);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    // eslint-disable-next-line
    let taxform = "";
    const TaxFormIdAPI = async () => {
        setPageLoading(true);
        await createAPIEndpoint()
            .getSegTaxID(
                `${Endpoint.GetCustomerInformation}?CICode=${auth.CICode}&strCustId=${CustId}`
            )
            .then((res) => {
                setCustomerData(res.data.Table[0]);
                if (CustId !== undefined) {
                    formik.values.payment_term = res.data.Table[0]?.MASK_PAY_TERM_DESC;
                    formik.setFieldValue(
                        "payment_term",
                        res.data.Table[0]?.MASK_PAY_TERM_DESC
                    );
                    formik.values.sales_executive = res.data.Table[0]?.MASK_EMP_NAME;
                    formik.setFieldValue(
                        "sales_executive",
                        res.data.Table[0]?.MASK_EMP_NAME
                    );
                    formik.setFieldValue("payment_mode", res.data.Table[0]?.MASK_MOP_DESC);
                    // setTaxFormId(res.data.Table[0].MASK_TAX_FORM_ID);
                    setMopId(res.data.Table[0]?.MASK_MOP_ID);
                    taxform = res.data.Table[0]?.MASK_TAX_FORM_ID;
                }
                setPageLoading(false);
                PopulateProductCode();
            });
        await createAPIEndpoint()
            .get(`${Endpoint.GetCustomerData}?CompId=${auth.CompId}&CustID=${CustId}`)
            .then((res) => {
                setCustomerData2(res.data.Data[0]);

                if (CustId !== undefined) {
                    createAPIEndpoint()
                        .getRegist(`${Endpoint.GetRegistDesc}?strRegistCode=${res.data.Data[0]?.REGIST_CODE}`)
                        .then((res) => {
                            setRegistCode(res.data);
                        });
                    createAPIEndpoint()
                        .getSegTaxID(`${Endpoint.GetExchRate}?CICode=${auth.CICode}&CurrencyId=${res.data.Data[0]?.MASK_CURRENCY_ID}&DefinedBy=E&CurrencyCode=${res.data.Data[0].MASK_CURRENCY_ID}`)
                        .then((res) => {
                            setExchRate(res.data);
                        });
                    createAPIEndpoint()
                        .getSegTaxID(
                            `${Endpoint.GetCustFinancialInfo}?CICode=${auth.CICode}&strCustId=${CustId}&strTransType=ChkInHand&strCompId=${auth.CompId}`
                        )
                        .then((res) => {
                            setChkInHnd(res.data[0]);
                        });
                }
            });

        await createAPIEndpoint()
            .get(`${Endpoint.GetBranchByWH}?WarehouseId=${whid}`)
            .then((res) => {
                createAPIEndpoint()
                    .getSegTaxID(
                        `${Endpoint.GetSerieByBranch}?CICode=${auth.CICode}&strBranchId=${res.data?.Data[0]?.BRANCH_ID}`
                    )
                    .then((res) => {
                        setSerie(res.data);
                    });
                setBranchId(res.data?.Data[0]?.BRANCH_ID);
            });
    };

    useEffect(() => {
        createAPIEndpoint()
            .get(
                `${Endpoint.GetSaleDiscPercByMOP}?MopId=${onChangeMOPFlag ? formik.values.payment_mode.MASK_MOP_ID : MopId
                }`
            )
            .then((res) => {
                setSalesDiscPerc(res.data.Data[0]);
            });
    }, [formik.values.payment_mode]);

    const PopulateProductCode = async () => {
        let response = [];
        await createAPIEndpoint()
            .get(
                `${Endpoint.GetAllProduct}?DivnId=${DivnID}&WhId=${whid}&CompId=${auth.CompId}`
            )
            .then((res) => {
                response = res.data.Data;
                let values = response.map((val) => {
                    return val;
                });

                setProductCodeSuggestion(values);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        PopulateWareHouse();
        populateCustomer();
        PopulatePayTerm();
        PopulateSalesExec();
        PopulateMOP();
        divnID();
    }, []);

    useEffect(() => {
        TaxFormIdAPI();
    }, [CustId, whid]);

    const searchWH = (e) => {
        setTimeout(() => {
            let _filteredWH;
            if (!e.query.trim().length) {
                _filteredWH = [...wareHouseSuggestion];
            } else {
                _filteredWH = wareHouseSuggestion.filter((WH) => {
                    return WH.DESCRIPTION.toLowerCase().includes(e.query.toLowerCase());
                });
            }

            setWareHouse(_filteredWH);
        }, 250);
    };

    const searchCustomer = (e) => {
        setTimeout(() => {
            let _filteredCustomer;
            if (!e.query.trim().length) {
                _filteredCustomer = [...customerSuggestion];
            } else {
                _filteredCustomer = customerSuggestion.filter((customer) => {
                    return customer.NAME.toLowerCase().includes(e.query.toLowerCase());
                });
            }
            setCustomer(_filteredCustomer);
        }, 250);
    };

    const searchPayTerm = (e) => {
        setTimeout(() => {
            let _filteredPayTerm;
            if (!e.query.trim().length) {
                _filteredPayTerm = [...payTermSuggestion];
            } else {
                _filteredPayTerm = payTermSuggestion.filter((payterm) => {
                    return payterm.DESCRIPTION.toLowerCase().includes(
                        e.query.toLowerCase()
                    );
                });
            }
            setPayTerm(_filteredPayTerm);
        }, 250);
    };
    const searchSE = (e) => {
        setTimeout(() => {
            let _filteredSE;
            if (!e.query.trim().length) {
                _filteredSE = [...salesExecSuggestion];
            } else {
                _filteredSE = salesExecSuggestion.filter((salesExcecutive) => {
                    return salesExcecutive.NAME.toLowerCase().includes(
                        e.query.toLowerCase()
                    );
                });
            }
            setSalesExec(_filteredSE);
        }, 250);
    };

    const searchMOP = (e) => {
        setTimeout(() => {
            let _filteredMOP;
            if (!e.query.trim().length) {
                _filteredMOP = [...mopSuggestion];
            } else {
                _filteredMOP = mopSuggestion.filter((MOP) => {
                    return MOP.DESCRIPTION.toLowerCase().includes(e.query.toLowerCase());
                });
            }
            setMOP(_filteredMOP);
        }, 250);
    };

    const searchProductCode = (e) => {
        setTimeout(() => {
            let _filteredProductCode;
            if (!e.query.trim().length) {
                _filteredProductCode = [...productCodeSuggestion];
            } else {
                _filteredProductCode = productCodeSuggestion.filter((productCode) => {
                    return (
                        productCode.DESCRIPTION.toLowerCase().includes(
                            e.query.toLowerCase()
                        ) || productCode.CODE.toLowerCase().includes(e.query.toLowerCase())
                    );
                });
            }
            setProductCode(_filteredProductCode);
        }, 250);
    };

    // const onEditorValueChange = (rowData, field, value) => {
    //     let updatedData = [...populateDataTable];
    //     updatedData.find((row) => row === rowData)[field] = value;
    //     setPopulateDataTable(updatedData);
    // };

    // const numberEditor = (props) => {
    //     return (
    //         <InputText
    //             class="form-control"
    //             type="text"
    //             id="editField"
    //             name="editField"
    //             keyfilter="pint"
    //             value={props.rowData[props.field]}
    //             onChange={(e) =>
    //                 onEditorValueChange(props.rowData, props.field, e.target.value)
    //             }
    //             style={{ width: "80px", height: "28px" }}
    //         />
    //     );
    // };

    const handleSave = (obj1) => {
        formik.values.lot_no =
            populateDataTable[populateDataTable.length - 1].pvcINVD_PROD_BATCH_NO;
        formik.values.available_quantity =
            populateDataTable[populateDataTable.length - 1].available_quantity;
        formik.values.product_code =
            populateDataTable[populateDataTable.length - 1].pvcPROD_ID;
        formik.values.product_description =
            populateDataTable[populateDataTable.length - 1].pvcPROD_LONG_DESC;
        formik.values.order_quantity =
            populateDataTable[populateDataTable.length - 1].pnmINVD_TOT_INV_QTY;

        obj1.some((val) => {
            if (
                parseInt(val.order_quantity) == 0 ||
                val.order_quantity == "" ||
                parseInt(val.order_quantity) > parseInt(val.available_quantity)
            ) {
                toast.error("Please enter valid order quantity", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                return true;
            }
            invoiceSave(obj1);
            return true;
        });
    };

    // post api payload
    const invoiceSave = async (obj1) => {
        setPageLoading(true);
        const newPrdDtl = populateDataTable.map((item) => {
            const updatedItem = { ...item };
            delete updatedItem.remarks;
            delete updatedItem.payTermId;
            delete updatedItem.cust_name;
            delete updatedItem.payment_mode;
            delete updatedItem.stock_type;
            delete updatedItem.available_quantity;
            return updatedItem;
        });

        // API FOR SAVE DATA
        const saveObj = {
            ciCode: auth.CICode,
            userId: auth.UserId,
            pvcINVH_ID: "",
            pvcINVH_NO: "",
            pvcINVH_DATE: currentDate,
            pvcINVH_TYPE: "D",
            pvcCOMP_ID: auth.CompId,
            pvcDIVN_ID: "GEN",
            pvcSOHD_ID: "",
            pvcSOHD_CREATE_EMP_ID: customerData2.EMP_ID,
            pvcPAY_TERM_ID: customerData2.MASK_PAY_TERM_ID,
            pvcPAY_TERM_EXPL: customerData2.MASK_PAY_TERM_DESC,
            pvcTAX_FORM_ID: customerData2.MASK_TAX_FORM_ID,
            pvcVAN_RT_ID: customerData2.MASK_VAN_RT_ID,
            pvcWH_ID: whid,
            pvcCURRENCY_ID: customerData2.MASK_CURRENCY_ID,
            pnmINVH_EXCH_RATE: exchRate,
            pnmINVH_TRADE_DISC_PERC: customerData2.TRADE_DISCOUNT?.toString(),
            pvcCUST_ID: CustId,
            pvcCUST_NAME: obj1[populateDataTable.length - 1].cust_name,
            pvcCUST_TYPE: "G",
            pvcCBILL_ADD1: customerData2.CBILL_ADD1,
            pvcCBILL_ADD2: customerData2.CBILL_ADD2,
            pvcCBILL_ADD3: "",
            pvcCBILL_ADD4: "",
            pvcCBILL_ADD_GEOG_ID: customerData2.CBILL_ADD_GEOG_ID,
            pvcCBILL_ADD_PIN: customerData2.CBILL_ADD_PIN,
            pvcCSHIP_ADD1: customerData2.CSHIP_ADD1,
            pvcCSHIP_ADD2: customerData2.CSHIP_ADD2,
            pvcCSHIP_ADD3: "",
            pvcCSHIP_ADD4: "",
            pvcCSHIP_ADD_GEOG_ID: customerData2.CSHIP_ADD_GEOG_ID,
            pvcCSHIP_ADD_PIN: customerData2.CSHIP_ADD_PIN,
            pvcINVH_CHALLAN_NO: "",
            pvcINVH_CHALLAN_DATE: "",
            pnmINVH_ROUND_OFF_AMT: "0.00",
            pnmINVH_TOT_BASE_CRNC_AMT: "0.00",
            pnmINVH_TOT_AMT: "0.00",
            pnmINVH_PRINT_NO: "",
            pvcINVH_PRINT_MESSAGE: "",
            pvcINVH_BILLING_INSTRUCTION: billingInst,
            pvcINVH_REGIST_CODE: registCode,
            pvcINVH_REGIST_NUMBER: customerData2.REGIST_NUMBER,
            pvcINVH_GATE_PASS_NO: "",
            pvcINVH_DISPUTE_REASON_ID: "",
            pvcINVH_DISP_EMP_ID: "",
            pnmCHQS_IN_HAND: chkInHnd.ChkInHand,
            pnmCLOSING_BALANCE: chkInHnd.ClosingBalance,
            pvcINVH_RESOLUTION_DATE: "",
            pvcINVH_TARGET_DATE: "",
            pvcMOP_ID: onChangeMOPFlag
                ? obj1[populateDataTable.length - 1].payment_mode
                : customerData.MASK_MOP_ID,
            pvcLOC_ID: "N",
            pvcORDM_ID: ORDM, //
            pvcDEFINED_BY: "E",
            pvcTAX_AREA_ID: customerData2.TAX_AREA_ID,
            pvcSTATUS: "OP",
            pnmTOTAPAR: "0.00",
            pvcEVAL_BY_LOT_TAG: "N",
            pvcREMARKS: obj1[populateDataTable.length - 1].remarks,
            pvcINVH_SAL_ACCT_ID: salActId,
            pvcINVH_SAL_DISC_ACCT_ID: salDiscId,
            pvcINVH_ROUNDOFF_ACCT_ID: roundOff,
            pvcCFOP_ID: "",
            pvcTRANSCHARGE_ID: "",
            pnmMOP_DISC_PER: salesDiscPerc.SalesDiscPerc,
            pvcMOP_DISC_INCLD: "Y",
            pnmTRANSCHARGE_RATE: customerData2.TRANS_CHARGE_ID,
            pvcCUST_PO_NUMBER: "",
            pvcCUST_PO_DATE: currentDate,
            pvcBRANCH_ID: branchId,
            pvcTRANSPORTER_ID: "",
            pnmSALESMAN_PERC: "0.00",
            pvcBANK_ID: customerData2.BANK_ID,
            pvcSALES_AREA_ID: customerData2.SALES_AREA_ID,
            pnmSHIP_SL_NO: customerData2.Column3,
            pnmTOT_NO_OF_PACKAGES: "0",
            pvcPARTY_ADD1: customerData2.PARTY_ADD_LINE1,
            pvcPARTY_ADD2: customerData2.PARTY_ADD_LINE2,
            pvcPARTY_GEOG_ID: customerData2.PARTY_ADD_GEOG_ID,
            pvcPARTY_ADD_PIN: customerData2.PARTY_ADD_PIN,
            pvcDELIVERY_INSTR: delivInstr,
            pvcCBILL_CONT_FNAME: customerData2.CBILL_CONT_FNAME,
            pvcESPECIE: ESPECIE,
            pvcSERIE: serie,
            pvcCIF_FOB: "",
            iukey: "1",
            pvcDOC_ID: onChangePaytermFlag
                ? obj1[populateDataTable.length - 1].payTermId
                : customerData.MASK_PAY_TERM_ID,
            pvcDUE_DATE: currentDate,
            pvcDOC_TYPE: "INV",
            pvcTYPE: "X",
            productList: newPrdDtl,
        };

        // return;
        createAPIEndpoint()
            .post(`${Endpoint.SaveDeliveryChallan}`, JSON.stringify(saveObj), {
                headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
                if (res.data.Status == "Success") {
                    setPageLoading(false);
                    toast.success(`${res.data.Message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                    setDeliveryChallanNo(res.data.Data[0].INVHNO);
                    createAPIEndpoint()
                        .get(
                            `${Endpoint.PrintDeliveryChallan}?invhId=${res.data.Data[0].INVHID}`
                        )
                        .then((res) => {
                            setPageLoading(false);
                            setPrintData(res.data.Data);
                            setDCPopup(true);
                        });

                    setDisableFlag(false);
                    setCartDisable(true);
                } else {
                    if (res.data.Status == "Warning") {
                        setPageLoading(false);
                        toast.warning(`${res.data.Message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                    }
                }
            })
            .catch((error) => {
                setPageLoading(false);
                toast.warning("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
                console.log(error);
            });
    };

    const productDistribution = (e) => {
        formik.setFieldValue(
            "available_quantity",
            Number(e.target.value.STOCK_AVAILABLE).toFixed(2)
        );
        formik.setFieldValue("lot_no", e.target.value.PROD_BATCH_NO);
        formik.setFieldValue("product_description", e.target.value.DESCRIPTION);
    };

    const handleDeletecart = (rowData) => {
        const updatedData = populateDataTable.filter(item => {
            return item.pvcPROD_ID !== rowData.pvcPROD_ID || item.pvcINVD_PROD_BATCH_NO !== rowData.pvcINVD_PROD_BATCH_NO;
        });
        setPopulateDataTable(updatedData);
    };

    const renderActions = (rowData) => {
        return (
            <div>
                <button className="grid-icon" onClick={() => handleDeletecart(rowData)}>
                    <Fa.FaTimes className="cancel" />
                </button>
            </div>
        );
    };

    if (pageLoading || customerLoading) {
        return (
            <>
                <Loader />
            </>
        );
    } else {
        return (
            <div className="col-12 pt-3">
                <div className="float-end">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        Refresh <MdRefresh style={{ fontSize: 18 }} />
                    </button>
                </div>
                <h3 className="mb-3 mt-2">Delivery Challan</h3>
                <div className="">
                    <form onSubmit={formik.handleSubmit}>
                        <section className="sales-box">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">
                                        Delivery Challan Number <span className="mandatory">*</span>
                                    </label>
                                    <InputText
                                        class="form-control"
                                        type="text"
                                        id="delivery_Challan_no"
                                        name="delivery_Challan_no"
                                        value={
                                            (formik.values.delivery_Challan_no = deliveryChallanNo)
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">
                                        Delivery Challan Date <span className="mandatory">*</span>
                                    </label>
                                    <InputText
                                        class="form-control"
                                        type="text"
                                        name="delivery_Challan_date"
                                        value={(formik.values.delivery_Challan_date = currentDate)}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <label className="form-label">
                                        WareHouse <span className="mandatory">*</span>
                                    </label>
                                    <div className="d-block">
                                        <AutoComplete
                                            inputId="ac"
                                            field="DESCRIPTION"
                                            name="warehouse"
                                            value={formik.values.warehouse}
                                            suggestions={wareHouse}
                                            completeMethod={searchWH}
                                            onChange={(e) => {
                                                formik.setFieldValue("warehouse", e.value);
                                            }}
                                            dropdown={true}
                                            itemTemplate={WareHouseTemplate}
                                            emptyMessage="No results found."
                                            showEmptyMessage
                                            className={classNames({
                                                "p-invalid": isFormFieldInvalid("warehouse"),
                                            })}
                                            disabled={disableFlag || cartDisable ? true : false}
                                        />
                                        {getFormErrorMessage("warehouse")}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="form-label">
                                        Customer Name <span className="mandatory">*</span>
                                    </label>
                                    <div className="d-block">
                                        <AutoComplete
                                            field="NAME"
                                            name="cust_name"
                                            value={formik.values.cust_name}
                                            suggestions={customer}
                                            completeMethod={searchCustomer}
                                            onChange={(e) => {
                                                formik.setFieldValue("cust_name", e.value);
                                            }}
                                            dropdown={true}
                                            itemTemplate={CustomerTemplate}
                                            emptyMessage="No results found."
                                            showEmptyMessage
                                            disabled={disableFlag || cartDisable ? true : false}
                                            className={classNames({
                                                "p-invalid": isFormFieldInvalid("cust_name"),
                                            })}
                                        />
                                        {getFormErrorMessage("cust_name")}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="form-label">
                                        Sales Executive <span className="mandatory">*</span>
                                    </label>
                                    <div className="d-block">
                                        <AutoComplete
                                            field="NAME"
                                            name="sales_executive"
                                            value={formik.values.sales_executive}
                                            suggestions={salesExec}
                                            completeMethod={searchSE}
                                            onChange={(e) => {
                                                // setOnchangeSEFlag(true);
                                                formik.setFieldValue("sales_executive", e.value);
                                            }}
                                            dropdown={true}
                                            itemTemplate={SalesExecTemplate}
                                            emptyMessage="No results found."
                                            showEmptyMessage
                                            className={classNames({
                                                "p-invalid": isFormFieldInvalid("sales_executive"),
                                            })}
                                            disabled={cartDisable ? true : false}
                                        />
                                        {getFormErrorMessage("sales_executive")}
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <label className="form-label">
                                        Payment Term <span className="mandatory">*</span>
                                    </label>
                                    <div className="d-block">
                                        <AutoComplete
                                            field="MASK_PAY_TERM_ID"
                                            name="payment_term"
                                            value={formik.values.payment_term}
                                            suggestions={payTerm}
                                            completeMethod={searchPayTerm}
                                            onChange={(e) => {
                                                setOnchangePaytermFlag(true);
                                                formik.setFieldValue("payment_term", e.value);
                                            }}
                                            dropdown={true}
                                            itemTemplate={PayTermTemplate}
                                            emptyMessage="No results found."
                                            showEmptyMessage
                                            className={classNames({
                                                "p-invalid": isFormFieldInvalid("payment_term"),
                                            })}
                                            disabled={cartDisable ? true : false}
                                        />
                                        {getFormErrorMessage("payment_term")}
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <label className="form-label">
                                        Mode of Payment <span className="mandatory">*</span>
                                    </label>
                                    <div className="d-block">
                                        <AutoComplete
                                            field="DESCRIPTION"
                                            name="payment_mode"
                                            value={formik.values.payment_mode}
                                            suggestions={MOP}
                                            completeMethod={searchMOP}
                                            onChange={(e) => {
                                                setOnchangeMOPFlag(true);
                                                formik.setFieldValue("payment_mode", e.value);
                                            }}
                                            dropdown={true}
                                            itemTemplate={MOPTemplate}
                                            emptyMessage="No results found."
                                            showEmptyMessage
                                            className={classNames({
                                                "p-invalid": isFormFieldInvalid("payment_mode"),
                                            })}
                                            disabled={cartDisable ? true : false}
                                        />
                                        {getFormErrorMessage("payment_mode")}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <label className="form-label">Remarks</label>
                                    <InputText
                                        class="form-control"
                                        type="text"
                                        id="remarks"
                                        name="remarks"
                                        value={formik.values.remarks}
                                        onChange={(e) => {
                                            formik.setFieldValue("remarks", e.target.value);
                                        }}
                                        className={classNames({
                                            "p-invalid": isFormFieldInvalid("remarks"),
                                        })}
                                        disabled={cartDisable ? true : false}
                                    />
                                    {getFormErrorMessage("remarks")}
                                </div>
                            </div>
                        </section>

                        <section className="sales-box">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="form-label">
                                        Product Code <span className="mandatory">*</span>
                                    </label>
                                    <div className="d-block">
                                        <AutoComplete
                                            field="DESCRIPTION"
                                            name="product_code"
                                            value={formik.values.product_code}
                                            suggestions={productCode}
                                            completeMethod={searchProductCode}
                                            onChange={(e) => {
                                                productDistribution(e);
                                                formik.setFieldValue("product_code", e.value);
                                            }}
                                            dropdown={true}
                                            itemTemplate={ProductCodeTemplate}
                                            emptyMessage="No results found."
                                            showEmptyMessage
                                            className={classNames({
                                                "p-invalid": isFormFieldInvalid("product_code"),
                                            })}
                                            disabled={cartDisable ? true : false}
                                        />
                                        {getFormErrorMessage("product_code")}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Product Description</label>
                                    <InputText
                                        class="form-control"
                                        type="text"
                                        id="product_description"
                                        name="product_description"
                                        value={formik.values.product_description}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                "product_description",
                                                e.target.value
                                            );
                                        }}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Lot Number</label>
                                    <InputText
                                        class="form-control"
                                        type="text"
                                        id="lot_no"
                                        name="lot_no"
                                        value={formik.values.lot_no}
                                        onChange={(e) => {
                                            formik.setFieldValue("lot_no", e.target.value);
                                        }}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Available Quantity</label>
                                    <InputText
                                        class="form-control"
                                        type="text"
                                        id="available_quantity"
                                        name="STOCK_AVAILABLE"
                                        value={formik.values.available_quantity}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                "available_quantity",
                                                e.target.value
                                            );
                                        }}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <label className="form-label">
                                        Order Quantity <span className="mandatory">*</span>
                                    </label>
                                    <InputText
                                        class="form-control"
                                        type="text"
                                        id="order_quantity"
                                        name="order_quantity"
                                        value={formik.values.order_quantity}
                                        onChange={(e) => {
                                            formik.setFieldValue("order_quantity", e.target.value);
                                        }}
                                        className={classNames({
                                            "p-invalid": isFormFieldInvalid("order_quantity"),
                                        })}
                                        disabled={cartDisable ? true : false}
                                    />
                                    {getFormErrorMessage("order_quantity")}
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="float-end mt-2">
                                        {!cartDisable && (
                                            <button
                                                type="submit"
                                                className="btn btn-outline-primary me-3"
                                            >
                                                Add To Cart
                                            </button>
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            disabled={
                                                !disableFlag || populateDataTable.length == 0
                                                    ? true
                                                    : false
                                            }
                                            onClick={() => {
                                                handleSave(populateDataTable);
                                            }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form>
                </div>
                <section className="mt-3">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3>All Records</h3>
                            <div className="DataTable pt-1 pb-4">
                                <DataTable
                                    value={populateDataTable}
                                    dataKey="MASK_PROD_ID"
                                    tableStyle={{ minWidth: "50rem" }}
                                    showGridlines
                                    stripedRows
                                    scrollable
                                >
                                    <Column field="stock_type" header="Stock Type"></Column>
                                    <Column field="pvcPROD_ID" header="Product Code"></Column>
                                    <Column
                                        field="pvcPROD_LONG_DESC"
                                        header="Product Description"
                                    ></Column>
                                    <Column
                                        field="pvcINVD_PROD_BATCH_NO"
                                        header="Product Lot No"
                                    ></Column>
                                    <Column
                                        field="pnmINVD_TOT_INV_QTY"
                                        header="Quantity(Packs)"
                                    ></Column>
                                    {!cartDisable && (
                                        <Column body={renderActions} header="Action "></Column>
                                    )}
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <PrintView />
                </section>
            </div>
        );
    }
};

export default DeliveryChallan;
